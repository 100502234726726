.update-holder {
    height: 60px;
    border: 1px solid grey;
}

.update-message {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 60px;
  background: white;
}