.dropzone {
  .delete {
    position: absolute;
    top: 8px;
    right: 24px;
    cursor: pointer;
    color: #ef3340;
    font-size: 18px;
  }

  .dz-preview {
    .dz-error-message {
      top: 0 !important;
      opacity: 1;
    }
  }
}
