// Language switcher
ul {
  &.language-switcher {
    list-style: none;
    margin: 0;
    li {
      display: inline;
      padding: 5px;
      margin-right: 4px;
      cursor: pointer;
      &.active {
        background-color: #009636;
        color: white;
      }
    }
  }
}