.footer {
  position: absolute;
  width: 100%;
  bottom: -100px;
  display: block;
  background-color: white;
  .footer-logo {
    display: inline-block;
    margin-top: 26px;
    height: 40px;
    vertical-align: middle;
  }
  .footer-copyright {
    display: inline-block;
    margin-top: 26px;
    margin-left: 16px;
    line-height: 1em;
    vertical-align: middle;
  }

  nav {
    margin-top: 34px;
    ul {
      list-style: none;
      li {
        display: inline-block;
        margin: 0 10px;
        a {
          color: #0072ce;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .footer {
    bottom: -210px;
    text-align: center;
  }
}
