@import "../../styles/theme";

.tabs {
  width: 100%;
  ul {
    &.tab {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        &.tab__item {
          display: inline-block;
          a {
            display: block;
            color: black;
            padding: 10px 20px;
            text-decoration: none;
          }

          border-bottom: 3px solid white;

          &:hover, &.is-selected {
            border-bottom: 3px solid map_get($theme-colors, blue);

            a {
              color: map_get($theme-colors, blue);
            }
          }
        }
      }
    }
  }
  .tab-content {
    padding: 20px;
    border: none;
  }
}

.tab-content {
  .tab-pane {
    &.active {
      background: none;
      color: #656565;
    }
  }
}