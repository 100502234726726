@import "../../styles/theme";

.form {
  border: 1px solid map-get($theme-colors, blue);
  //padding: 20px;
  .title {
    background: map-get($theme-colors, blue);
    color: white;
    padding: 10px;
  }

  .form-group {
    margin: 20px;
  }

  .action {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}