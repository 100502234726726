.privateabi {
    font-weight: bold;
    color: white;
    padding-right: 15px;  
    a {
        color: white;
        &:hover {
            color: black;
        }
    }
}