@import '../../styles/theme';

.login {
  position: inherit;
  //width: 120px;
  font-size: 12pt;
  a {
    position: relative;
    display: block;
    padding-left: 24px;
    text-decoration: none !important;
    em {
      position: absolute;
      top: 4px;
      left: 0;
    }
    color: map_get($theme-colors, green);
  }
  .login-mask {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    background: #fff;
    opacity: 0.8;
    z-index: 100;
  }

  .login-form {
    text-align: left;
    position: absolute;
    top: 62px;
    right: 0;
    background: white;
    z-index: 101;
    width: 320px;
    min-height: 265px;
    border: 1px solid #dde6e9;

    .tabs {
      padding: 0;
    }

    .tab__item {
      width: 50%;
      text-align: center;
      padding: 10px;
    }

  }
}

.logout {
  padding: 16px 0;
  display: inline-block;
  a {
    position: relative;
    display: inline-block;
    padding-right: 26px;
    color: map_get($theme-colors, green);
    text-decoration: none;
    i {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}