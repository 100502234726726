ul {
  &.notification {
    list-style: none;
    position: fixed;
    top: 48px;
    z-index: 1200;
    background: none;
    border: none;
    width: 280px;
    margin: 10px 0;
    right: 0;
    li {
      background: none;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
}
