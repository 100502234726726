@import '~font-awesome/css/font-awesome.min.css';

// Public layout
.public-layout {
  position: relative;
  margin-bottom: 100px;
  min-height: calc(100% - 100px);
}

@media only screen and (max-width: 600px) {
  .react-datepicker__month-container {
    width: 200px;
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 1.3rem;
  }
}

// TODO file
.ReactModalPortal {
  .ReactModal__Overlay {
    z-index: 1000 !important;
  }

  .ReactModal__Content {
    padding: 0 !important;
    border-radius: 0 !important;
    border: 1px solid #0072ce !important;

    .form {
      border: none;
      //margin-top: 20px;
      //margin-bottom: 20px;
    }

    .title {
      width: 100%;
      padding: 5px 10px;
      background-color: #0072ce;
      color: white;

      i {
        position: absolute;
        top: 6px;
        right: 10px;
        cursor: pointer;
      }
    }

    .container {
      padding-top: 10px;
    }
  }
}

div.react-datepicker {
  //min-width: 313px;
}

nav.navbar.topnavbar {
  z-index: 999;
}

.paddings-0 {
  padding: 0 !important;
}

.problem-state {
  margin: 7px;
}

.playground-object-id-label {
  margin: 7px;
}

a.icon-btn {
  margin-left: 0 !important;
}

.icon-btn {
  padding: 0;
  width: 20px;
}

.problem-comment {
  &:not(:first-of-type) {
    border-top: 1px solid #e4eaec;
    padding-top: 10px;
  }

  .form {
    border: 1px solid lightgray;
  }
}

.problems-table {
  .react-datepicker-wrapper, .react-datepicker__input-container {
    display: block;
  }

  .react-datepicker__input-container input {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 2;
  }
}

//.card {
//  &.card-default {
//    padding-left: 5px;
//    padding-right: 5px;
//  }
//}

.up-to-map {
  &.select {
    & > div:nth-of-type(2) {
      z-index: 1002;
    }
  }

  .css-15k3avv {
    z-index: 1002;
  }
}

.react-datepicker-popper {
  z-index: 1002;
}

// Tables
.table {
  .form-group {
    margin-bottom: 0;
  }
}

form.form-group-no-margins {
  .form-group {
    margin-bottom: 0;
  }
}

.table-responsive {
  margin-bottom: 30px;
}

div.ReactTable .rt-noData, .ReactTable .-loading > div {
  z-index: 0;
  top: 20vh;
}

.ads-finder {
  .css-vj8t7z {
    display: none;
  }

  .css-15k3avv {
    z-index: 1002;
  }
}

.hide-select-list {
  .css-15k3avv {
    display: none;
  }
}

.hide-select-clear {
  .css-1ep9fjw, .css-1uq0kb5 {
    display: none;
  }
}

.map-hide-draw-edit-remove {
  .leaflet-draw-edit-remove {
    //display: none;
  }
}

@media only screen and (max-width: 339px) {
  .wrapper .aside-container .aside-inner {
    padding-top: 110px;
  }
}

@media only screen and (max-width: 600px) {
  .react-datepicker__month-container {
    width: 200px;
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 1.3rem;
  }
}

.problem-id-link {
  display: block;
  height: 100%;
}

.playground-object-id-link {
  display: block;
  height: 100%;
  color: #0072ce;
}

.gallery-img {
  margin-right: 5px;
  cursor: pointer;
  float: left;
  margin-bottom: 5px;
}

.label-no-margins {
  label {
    margin: 0;
  }
}

.checkbox-swal {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}

.leaflet-draw-toolbar a.leaflet-draw-toolbar-button-enabled,
.leaflet-draw-toolbar a.leaflet-draw-toolbar-button-enabled:hover,
.leaflet-control-styleeditor-interior.enabled,
.leaflet-control-styleeditor-interior.enabled:hover {
  background-color: #0eb7ff;
}

ul.leaflet-draw-actions.leaflet-draw-actions-bottom,
ul.leaflet-draw-actions.leaflet-draw-actions-top,
.leaflet-control-styleeditor-cancel {
  display: none !important;
}

.easy-button-button {
  &.leaflet-bar-part.leaflet-interactive {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 1rem;
    padding: 0;
  }
}

.leaflet-btn-save-active {
  border: none;
  color: #212529;
  background-color: #efc40f;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.leaflet-btn-save-active:hover {
  background-color: #d8b60d;
}

.leaflet-btn-delete-inactive-active {
  border: none;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.leaflet-btn-delete-inactive-active:hover {
  background-color: #F4F4F4;
}

.leaflet-btn-delete-active-active {
  border: none;
  color: #212529;
  background-color: #0eb7ff;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.content_13hv8io > .footer_60scne {
  height: auto;
}

//lightbox <img>
.image_1swebtw-o_O-imageLoaded_zgbg08 {
  max-height: calc(100vh - 90px) !important;
}

#loginForm {
  .radio-group__text {
    margin-bottom: 8px;
    font-weight: bold;
  }

  .radio {
    display: block;

    .radio__input {
      margin-right: 5px !important;
    }
  }
}

.modalAddObject {
  max-width: 400px;
  max-height: 500px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.border-none {
  border: none;
}

.padding-none {
  padding: 0;
}

/* Click fixer for btn with modal dialog inside */
div.action > a.btn.btn-danger {
  padding: 0;

  & > div[options="[object Object]"] {
    padding: 0.375rem 1rem;
  }
}


.sidebar-item-label {
  white-space: normal;
}

.a-href {
  color: #f3d03e;
  text-decoration: none;
  border: none;
  background: none;
  padding: 0;
}

.a-href:hover, .a-href:focus, .a-href:target {
  color: #D7B00E !important;
  background: none !important;
  text-decoration: underline;
}

.d-grid {
  display: grid;
}

.h-100 {
  height: 100%;
}

.modal-tender-detail {
  width: 1000px;
  max-width: 100%;
}

.break-words {
  word-break: break-word;
  white-space: normal;
}

.roadsigns-table .rt-td {
  height: 64px;
}

.nav-item.user-info {
  .nav-link {
    &:hover {
      color: white !important;
    }

    cursor: default;
    font-weight: bold;
  }
}

.myClassListName{
  max-height: 300px;
  overflow-y: auto;
}

table.no-first-top-border{
  tbody > tr:first-child > td {
    border: none;
  }
}

tr.align-center > td, tr.align-center > th{
  text-align: center;
}

.file-thumbnail-name{
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nb{
  font-size: 1.2em;
  font-weight: bold;
  color: #f05050;
  text-align: center;
}

.subscripe-info-btns-conatainer, .idcard-mobileid-btns-conatainer{
  text-align: right;
}

.checkbox-d-inline-block > .checkbox.c-checkbox{
  display: inline-block;
}

.align-top{
  vertical-align: top;
}

.align-bottom{
  vertical-align: bottom;
}

.evaluation-form-table{
  thead > tr > th {
    border-bottom: 1px solid lightgray;
  }
  tbody > tr > td {
    border-top: none;
  }
}

.background-transparent{
  background-color: transparent !important;
}

.table-layout-fixed{
  table-layout: fixed;
}

.overflow-x-hidden{
  overflow-x: hidden !important;
}

.overflow-x-auto{
  overflow-x: auto;
}

.overflow-y-inherit{
  overflow-y: inherit !important;
}

.overflow-visible{
  overflow: visible !important;
}

.tr-td-border-top-dark > td{
  border-top: 1px solid black;
}

.autocomplete{
  position: relative;

  div[role="listbox"]{
    position: absolute;
    margin-top: -4px;
    background: white;
    width: 100%;
    z-index: 100;

    ul{
      padding-left: 0;
      margin-bottom: 0;
      border: 1px solid lightgray;
      border-top: 1px dashed lightgray;
      border-radius: 0px 0px 10px 10px;

      li{
        padding-left: 15px;
        list-style: none;
        cursor: pointer;

        &:hover{
          background-color: #f8f9fa;
        }
      }
    }
  }
}

.customFields-container{
  .form-group{
    margin-bottom: 0;
  }

  margin-bottom: 1rem;
}

.content-wrapper .content-heading{
  &.embedded{
    font-size: 1.2rem;
    line-height: 1;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 5px;
    border-top: 1px solid #cfdbe2;
  }
}

.service-class-color-picker {
  width: 100px;
}

.needOfRepairFilesContainer{
  margin-top: 5px;
  background-color: #3a3f51;
  border-bottom: 1px solid #edf1f2;

  label[for="dropzone"]{
    padding-left: 5px;
    padding-top: 7px;
    color: white;
    font-weight: bold;
  }

  div.dropzone{
    background-color: #cacaca;
  }

  .table-responsive{
    background-color: #3a3f51;
    margin-bottom: 1px;
    th, td{
      color: white;
    }
  }
}

.table-logs{
  .-filters{
    .rt-th{
      overflow: visible;
    }
    .form-group{
      margin-bottom: 0px;
    }
  }
}

.addObjectByMap{
  .col1{
    width: 75px;
  }
  .col2{
    width: calc(100% - 75px - 100px);
  }
  .col3{
    width: 100px;
  }
  div.col3{
    text-align: right;
  }
}

.tender-questions-th{
  &>div{
    font-weight: bold;
    color: #484848;
  }
}

.tender-question-title{
  color: #0072ce;
}

.tender-question-answered{
  color: green;
}

input[type='text']{
  display: inline-block;
  &.mid-phone{
    width: 150px;
  }
  &.mid-idCode{
    width: 150px;
  }
}

.tree-public-data-table{
  tr > td{
    width: 20%;
  }

  th > h5{
    font-size: 1rem;
    font-weight: bold;
    font-style: italic;
  }
}

.ReactTable.-break-header{
  .rt-resizable-header-content{
    white-space: normal;
  }
}

.mid-sign-form{
  float: right;
  margin-top: 15px;

  .mid-phone, .mid-idCode, .mid-btn-ok{
    margin-right: 5px;
  }
}

.margin-left-5vw{
  margin-left: 5vw;
}