.swal-footer{
  text-align: center;

  button.swal-button.confirm{
    background-color: rgb(239, 51, 64);

    &:hover{
      background-color: rgb(218, 50, 57);
    }
  }
  button.swal-button.cancel{
    background-color: #c1c1c1;
    color: #fff;

    &:hover{
      background-color: #a2a2a2;
    }
  }
}