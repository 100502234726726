$theme-colors: (
        blue: #0072ce,
        red: #ef3340,
        green: #009639,
        yellow: #f3d03e,
        gray: #f5f5f5,
        primary: #0072ce,
        danger: #ef3340,
);
//

// fixed react table
.action {
  a {
    margin-left: 10px !important;
  }
}