@import "../../../../styles/theme";

header {
  width: 100%;
  background: map-get($theme-colors, gray);

  .pre-nav {
    background-color: white;
  }

  nav {
    &.bg-light {
      background-color: map-get($theme-colors, gray) !important;
    }
  }

  .navbar-light {

    .navbar-toggler {
      border: none;
      color: map-get($theme-colors, blue);
      &:focus {
        outline: none;
      }
    }

    .navbar-nav {
      .nav-link {
        padding-top: 12px;
        font-size: 12pt;
        color: black;
        border-bottom: 3px solid map-get($theme-colors, gray);
        &:hover, &.active {
          background: none;
          color: #0072ce;
          border-bottom: 3px solid map-get($theme-colors, blue);
        }
      }
    }
  }
}

.abi {
  padding-right: 15px;  
  a {
    color: #0072ce;
  }
}